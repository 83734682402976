
import { defineComponent, ref, toRefs, watch, computed } from 'vue'
import { Validation } from '@vuelidate/core'
import apiUseCase from '@/usecase/apiUseCase'
import icons from '@/views/components/icon/index'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import FormInput from '@/views/components/form/FormInput.vue'
import FormInputNumber from '@/views/components/form/FormInputNumber.vue'
import FormTextarea from '@/views/components/form/FormTextarea.vue'
import FormDatePicker from '@/views/components/form/FormDatePicker.vue'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonIcon from '@/views/components/button/ButtonIcon.vue'
import { IOrderForm, OrderDetails, ContractItem } from '@/utils/interfaces/iCreateOrder'
import ModalSelectItem from './ModalSelectItem.vue'

export default defineComponent({
  components: {
    FormInput,
    FormInputNumber,
    FormTextarea,
    FormDatePicker,
    ButtonPrimary,
    ButtonIcon,
    ModalSelectItem,
    currencyNumberFormat,
    ...icons
  },
  props: {
    formData: {
      type: Object as () => IOrderForm,
      required: true,
    },
    v$: {
      type: Object as () => Validation,
      required: true,
    },
    isOrderDetailsEmpty: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localFormData = ref({ ...props.formData })
    const shippingTypeOptions = ref(['AA', 'AB'])
    const orderDetails = ref<OrderDetails[]>(props.formData.orderDetails.length > 0 ? [...props.formData.orderDetails] : [])
    const isModalVisible = ref(false)

    const updateOrderDetails = () => {
      const updatedDetails = [...orderDetails.value]
      localFormData.value.orderDetails = updatedDetails
      emit('updateOrderDetails', updatedDetails)
    }

    const toggleModalVisibility = (isVisible: boolean) => {
      isModalVisible.value = isVisible
    }

    const addItemToTable = (selectedItems: ContractItem[]) => {
      const newItems = selectedItems.filter(
        item => !orderDetails.value.some(existingItem => existingItem.contractItem.id === item.id)
      )
      const newOrderDetails = newItems.map(item => ({
        contractItem: { ...item },
        packages: 1,
        weight: 0,
        volume: 0,
        price: 0,
        description: '',
        isLoading: false,
        errorMessage: '',
        additionalMessage: ''
      }))
      orderDetails.value.push(...newOrderDetails)
      updateOrderDetails()
    }

    const removeItemByID = (id: string) => {
      orderDetails.value = orderDetails.value.filter(item => item.contractItem.id !== id)
      updateOrderDetails()
    }

    const checkShippingRates = async (item: any) => {
      item.isLoading = true
      item.price = 0
      item.errorMessage = ''
      item.additionalMessage = ''

      try {
        const params = `?contractRouteId=${localFormData.value.contractRoute.id}&contractItemId=${item.contractItem.id}&weight=${item.weight}`
        const { result } = await apiUseCase.get('openapi/v1/shippingrates', params)

        item.price = result?.agentToAgentPrice
        item.additionalMessage = `Harga ini berlaku untuk satuan dengan rentang ${result?.minValue} - ${result?.maxValue} ${result?.contractItemUnit}`
        updateOrderDetails()
      } catch (error: any) {
        const { response } = error
        item.weight = 0
        if (!response) {
          console.log('Unknown error:', error.message || error)
          item.errorMessage = 'An unknown error occurred'
        } else {
          const { status, data } = response
          if (status === 404) {
            console.log('Error 404: Not Found')
            item.errorMessage = data.detail || 'Item not found.'
          } else if (status === 400) {
            console.log('Error 400: Validation errors')
            item.errorMessage = 'Validation errors occurred'
            if (data.errors) {
              Object.keys(data.errors).forEach((field) => {
                item[`${field.toLowerCase()}Error`] = data.errors[field][0]
              })
              item.errorMessage += `: ${Object.values(data.errors).flat().join(', ')}`
            }
          } else {
            console.log(`Unhandled Error: ${status}`)
            item.errorMessage = `Unhandled error occurred: ${status}`
          }
        }
      } finally {
        item.isLoading = false
      }
    }

    watch(orderDetails, updateOrderDetails)

    const existingContractItems = computed(() => orderDetails.value.map(item => item.contractItem))

    return { 
      localFormData, 
      ...toRefs(props.v$), 
      shippingTypeOptions,
      isModalVisible,
      toggleModalVisibility,
      addItemToTable,
      removeItemByID,
      checkShippingRates,
      orderDetails,
      existingContractItems,
      icons,
      currencyNumberFormat
    }
  },
})
