
import { defineComponent, ref, computed, onMounted, reactive, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { required, minLength, maxLength, numeric } from '@vuelidate/validators'
import { validIndonesianPhoneNumber } from '@/utils/helpers/validation'
import { handleApiError } from '@/utils/helpers/errorHandling'
import useVuelidate from '@vuelidate/core'
import icons from '@/views/components/icon/index'
import apiUseCase from '@/usecase/apiUseCase'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonSecondary from '@/views/components/button/ButtonSecondary.vue'
import FormInput from '@/views/components/form/FormInput.vue'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import { IOrderForm, OrderDetails } from '@/utils/interfaces/iCreateOrder'
import env from '@/configs/environtment'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'

export default defineComponent({
  components: {
    Step1,
    Step2,
    Step3,
    ButtonPrimary,
    ButtonSecondary,
    FormInput,
    HjpConfirmationModal,
    ...icons
  },
  setup() {
    const app = getCurrentInstance()
    const router = useRouter()
    const activeStep = ref(0)
    const isLoadingSubmit = ref(false)

    // Default form data
    const defaultOrderForm: IOrderForm = {
      contractRoute: { id: '', contractId: '', contractName: '', name: '', originName: '', destinationName: '' },
      sender: {
        name: '',
        phone: '',
        address: ''
      },
      recipient: {
        name: '',
        phone: '',
        address: ''
      },
      packageDetails: {
        receiptCode: 'MIISI.PAKET.TA.23.10.0000145',
        shippingType: 'AA',
        orderDate: new Date().toISOString().split('T')[0],
        totalPackages: 0,
        totalVolume: 0,
        totalWeight: 0,
        totalPrice: 0,
        notes: 'Barang sudah sesuai dengan pesanan'
      },
      orderDetails: [] as OrderDetails[]
    }

    const formData = ref<IOrderForm>({ ...defaultOrderForm })
    const { $toast } = app!.appContext.config.globalProperties

    // Validation rules
    const rules = {
      contractRoute: { id: { required } },
      sender: {
        name: { 
          required, 
          minLength: minLength(3),
          maxLength: maxLength(141)
        },
        phone: { 
          required,
          minLength: minLength(8),
          maxLength: maxLength(13),
          validIndonesianPhoneNumber
        },
        address: { 
          required, 
          minLength: minLength(7),
          maxLength: maxLength(255)
        }
      },
      recipient: {
        name: { 
          required,
          minLength: minLength(3),
          maxLength: maxLength(141)
        },        
        phone: { 
          required,
          minLength: minLength(8),
          maxLength: maxLength(13),
          validIndonesianPhoneNumber
        },        
        address: { 
          required, 
          minLength: minLength(7),
          maxLength: maxLength(255)
        }      
      },
      packageDetails: {
        receiptCode: { required },
        shippingType: { required },
        orderDate: { required },
        totalPackages: { required, numeric },
        totalVolume: { required, numeric },
        totalWeight: { required, numeric },
        totalPrice: { required, numeric },
        notes: { required },
      },
    }

    const v$ = useVuelidate(rules, formData)

    // Modal confirmation data
    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/route-save.svg'),
      title: 'Konfirmasi Pembuatan Order',
      subTitle: '',
      btnSubmitTitle: 'Buat Order',
    })

    // Environment (staging/production)
    const environment = computed(() => env.getMode())

    // Form reset functionality
    const resetForm = () => {
      formData.value = { ...defaultOrderForm }
    }

    // Error toast for validation
    const validateToastError = (message = 'Mohon periksa kembali form Anda. Pastikan semua field terisi dengan benar dan sesuai format yang ditentukan.') => {
      $toast.add({
        severity: 'error',
        detail: message,
        group: 'br',
        closable: false,
        life: 3000
      })
    }

    const updateOrderDetailsHandler = (newOrderDetails: OrderDetails[]) => {
      formData.value.orderDetails = newOrderDetails
    }

    // Style helper methods (Dynamic CSS classes)
    const getClassByEnv = (isActive: boolean, stagingClass: string, productionClass: string, defaultClass: string) => {
      if (isActive) {
        return environment.value === 'production' ? productionClass : stagingClass
      }
      return defaultClass
    }

    const stepClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'bg-secondary-100', 'bg-primary-100', 'bg-gray-200'),
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-500', 'text-gray-400')
    ]

    const lineClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'bg-secondary-500', 'bg-primary-700', 'bg-gray-300')
    ]

    const labelClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-500', 'text-gray-500'),
      'font-semibold'
    ]

    const subLabelClass = (isActive: boolean) => [
      getClassByEnv(isActive, 'text-secondary-500', 'text-primary-100', 'text-gray-400'),
      'text-sm'
    ]

    // Step validation logic
    type FieldNames = 'sender' | 'recipient' | 'contractRoute' | 'packageDetails'

    const validateStep = (fields: FieldNames[]): boolean => {
      fields.forEach(field => v$.value[field]?.$touch()) // Use optional chaining to avoid non-null assertion

      const hasOrderDetails = fields.includes('packageDetails') && formData.value.orderDetails.length > 0
      
      const areOrderDetailsValid = formData.value.orderDetails.every(detail => detail.weight > 0)

      if (fields.includes('packageDetails')) {
        if (!areOrderDetailsValid) {
          validateToastError('Mohon periksa inputan berat di kategori barang, pastikan nilai berat harus lebih dari 0')
          return false
        }

        if (!hasOrderDetails) {
          validateToastError()
          return false
        }
      }

      const isValid = fields.every(field => !v$.value[field]?.$invalid) 
      if (!isValid) {
        validateToastError()
      }

      return isValid
    }

    // Move to the next step
    const nextStep = (): void => {
      const currentStep = activeStep.value
      const fieldsToValidate: FieldNames[] = currentStep === 0 ? ['sender', 'recipient', 'contractRoute'] : ['packageDetails']

      if (validateStep(fieldsToValidate)) {
        activeStep.value++
      }
    }

    // Move to the previous step
    const prevStep = (): void => {
      if (activeStep.value > 0) activeStep.value--
    }

    // Manually set the current step
    const setStep = (step: number): void => {
      const currentStep = activeStep.value
      const fieldsToValidate: FieldNames[] = currentStep === 0 ? ['sender', 'recipient', 'contractRoute'] : ['packageDetails']

      if (step > currentStep && (!validateStep(fieldsToValidate) || (currentStep === 1 && formData.value.orderDetails.length === 0))) {
        return
      }

      if (step <= currentStep + 1) {
        activeStep.value = step
      }
    }

    // Submit the order
    const submitOrder = (): void => {
      const fieldsToValidate: FieldNames[] = activeStep.value === 0 ? ['sender', 'recipient'] : ['packageDetails']

      if (!validateStep(fieldsToValidate) || (activeStep.value === 1 && formData.value.orderDetails.length === 0)) {
        return
      }

      modalConfirmation.subTitle = `Apakah Anda yakin ingin membuat order untuk pengiriman <br>dari <strong>${formData.value.contractRoute.originName}</strong> ke <strong>${formData.value.contractRoute.destinationName}</strong> ?<br>Pastikan semua informasi sudah benar.`
      modalConfirmation.show = true
    }

    // Hide confirmation modal
    const hideModalConfirmation = () => {
      modalConfirmation.show = false
    }
        
    const doSubmitConfirm = async () => {
      isLoadingSubmit.value = true

      // Set payload 
      const payload = {
        contractRouteId: formData.value.contractRoute.id, 
        senderName: formData.value.sender.name, 
        senderAddress: formData.value.sender.address, 
        senderGeohash: '', 
        senderPhoneNumber: `62${formData.value.sender.phone}`,
        recipientName: formData.value.recipient.name, 
        recipientAddress: formData.value.recipient.address, 
        recipientGeohash: '',
        recipientPhoneNumber: `62${formData.value.recipient.phone}`,
        consignmentContent: formData.value.packageDetails.notes || '',
        consignmentValue: 1,
        orderDetails: formData.value.orderDetails.map((detail) => ({
          contractItemId: detail.contractItem.id,
          quantity: detail.packages,
          weight: detail.weight,
          volume: detail.volume,
          description: detail.description || '',
        }))
      }

      try {
        const { error } = await apiUseCase.postOrPut('openapi/v1/orders', null, payload)
        if (!error) {
          hideModalConfirmation()
          // Redirect to transaction 
          router.push({
            name: 'transaction',
            query: { tab: '1' } 
          })
        }
      } catch (err: any) {
        const response = err?.response || null  
        console.log('response err =>', response)
        handleApiError(response?.data, $toast)
      } finally {
        isLoadingSubmit.value = false
      }
    }

    // On component mount
    onMounted(() => {
      resetForm()
    })

    return {
      // Data & methods returned to the template
      environment,
      formData,
      activeStep,
      nextStep,
      prevStep,
      setStep,
      submitOrder,
      modalConfirmation,
      hideModalConfirmation,
      doSubmitConfirm,
      updateOrderDetailsHandler,
      v$,
      icons,
      isLoadingSubmit,
      // Style helpers
      stepClass,
      lineClass,
      labelClass,
      subLabelClass,
    }
  }
})
