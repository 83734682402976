
import Breadcrumb from '@/views/components/breadcrumbs/BreadcrumbMain.vue'
import LeftPanel from '@/views/layouts/panel/LeftPanel.vue'
import HeaderPanel from '@/views/layouts/panel/HeaderPanel.vue'
import FooterPanel from '@/views/layouts/panel/FooterPanel.vue'
import '@/assets/styles/scss/layout.scss'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import ChangePasswordDialog from '@/views/components/modal/HjpChangePasswordModal.vue'
import environment from '@/configs/environtment'
import router from '@/router'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const from = urlParams.get('from')

export default {
  name: 'Main',
  components: {
    LeftPanel,
    HeaderPanel,
    FooterPanel,
    Breadcrumb,
    ProgressSpinner,
    ChangePasswordDialog
  },
  methods: {
    renderPageTitle(val: any) {
      let title = val
      if (val === 'Dashboard' || val === 'Transaksi' || val === 'Terms of Payment') {
        title = ''
      } else if (from === 'transaction' || from === 'draft') {
        title = 'Detail Draft'
      }
      return title
    }
  },
  setup() {
    const store = useStore()
    const route: any = useRoute()
    const breadcrumbs = computed(() => store.state.ui.breadcrumbs)

    const checkLocalVersion = () => {
      const version = localStorage.getItem('version')
      if (version !== environment.getVersion() && store.state.device.isWeb) {
        localStorage.setItem('version', environment.getVersion())
        window.location.reload()
      }
    }

    onMounted(() => {
      checkLocalVersion()
      // console.log('store on main', store.state)
      // console.log('breadxrimbs', breadcrumbs)
    })

    return {
      breadcrumbs,
      store,
      route,
      router
    }
  }
}
