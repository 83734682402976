
import { defineComponent } from 'vue'

// Define the type for image mapping
type ImageMap = {
    [key: string]: string
}

// Create an image mapping
const images: ImageMap = {
    'resi-empty': require('@/assets/img/resi-empty.png'),
    'resi-draft-empty': require('@/assets/img/resi-draft-empty.png'),
    // Add other images as needed
}

export default defineComponent({
    props: {
        img: {
            type: String,
            default: 'resi-empty',
        },
        text: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const imagePath = images[props.img] || images['resi-empty']
        return {
            imagePath,
        }
    }
})
