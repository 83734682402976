import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stepper-horizontal-container flex justify-center items-center mb-8" }
const _hoisted_2 = { class: "step-label ml-4" }
const _hoisted_3 = { class: "step-label ml-4" }
const _hoisted_4 = { class: "step-label ml-4" }
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-12 gap-4" }
const _hoisted_6 = {
  key: 0,
  class: "col-span-12"
}
const _hoisted_7 = { class: "step-content bg-gray-50 p-8 rounded-lg relative" }
const _hoisted_8 = { class: "buttons-inside-card mt-8 flex justify-end" }
const _hoisted_9 = {
  key: 1,
  class: "col-span-12 md:col-span-10"
}
const _hoisted_10 = { class: "step-content bg-gray-50 p-8 rounded-lg relative" }
const _hoisted_11 = {
  key: 2,
  class: "col-span-12 md:col-span-2 flex flex-col justify-start space-y-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step1 = _resolveComponent("Step1")!
  const _component_Step2 = _resolveComponent("Step2")!
  const _component_ButtonSecondary = _resolveComponent("ButtonSecondary")!
  const _component_ButtonPrimary = _resolveComponent("ButtonPrimary")!
  const _component_Step3 = _resolveComponent("Step3")!
  const _component_HjpConfirmationModal = _resolveComponent("HjpConfirmationModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["step flex items-center cursor-pointer", ['step', { 'active-step': _ctx.activeStep >= 0, 'bg-gray-100 text-gray-400': _ctx.activeStep < 0 }]]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setStep(0)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["step-icon flex items-center justify-center rounded-lg p-2", _ctx.stepClass(_ctx.activeStep >= 0)])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons.ProfileIcon)))
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.labelClass(_ctx.activeStep >= 0))
          }, " Pengirim dan Penerima ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.subLabelClass(_ctx.activeStep >= 0))
          }, " Detail Pengirim dan Penerima ", 2)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["separator mx-4 h-px flex-1", _ctx.lineClass(_ctx.activeStep >= 1)])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["step flex items-center cursor-pointer", ['step', { 'active-step': _ctx.activeStep >= 1, 'bg-gray-100 text-gray-400': _ctx.activeStep < 1 }]]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setStep(1)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["step-icon flex items-center justify-center rounded-lg p-2", _ctx.stepClass(_ctx.activeStep >= 1)])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons.VehicleTruckCubeIcon)))
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.labelClass(_ctx.activeStep >= 1))
          }, " Detail Paket ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.subLabelClass(_ctx.activeStep >= 1))
          }, " Informasi Paket ", 2)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["separator mx-4 h-px flex-1", _ctx.lineClass(_ctx.activeStep >= 2)])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["step flex items-center cursor-pointer", ['step', { 'active-step': _ctx.activeStep >= 2, 'bg-gray-100 text-gray-400': _ctx.activeStep < 2 }]]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setStep(2)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["step-icon flex items-center justify-center rounded-lg p-2", _ctx.stepClass(_ctx.activeStep >= 2)])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons.DocumentIcon)))
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.labelClass(_ctx.activeStep >= 2))
          }, " Ringkasan Order ", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.subLabelClass(_ctx.activeStep >= 2))
          }, " Ringkasan info order ", 2)
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.activeStep < 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_Step1, {
                  formData: _ctx.formData,
                  environment: _ctx.environment,
                  v$: _ctx.v$
                }, null, 8, ["formData", "environment", "v$"])
              ], 512), [
                [_vShow, _ctx.activeStep === 0]
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_Step2, {
                  formData: _ctx.formData,
                  onUpdateOrderDetails: _ctx.updateOrderDetailsHandler,
                  environment: _ctx.environment,
                  v$: _ctx.v$,
                  "is-order-details-empty": _ctx.formData.orderDetails.length === 0
                }, null, 8, ["formData", "onUpdateOrderDetails", "environment", "v$", "is-order-details-empty"])
              ], 512), [
                [_vShow, _ctx.activeStep === 1]
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.activeStep > 0)
                  ? (_openBlock(), _createBlock(_component_ButtonSecondary, {
                      key: 0,
                      class: "mr-4",
                      buttonText: 'Kembali',
                      onClick: _ctx.prevStep
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ButtonPrimary, {
                  buttonText: 'Lanjutkan',
                  onClick: _ctx.nextStep
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeStep === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Step3, {
                formData: _ctx.formData,
                environment: _ctx.environment
              }, null, 8, ["formData", "environment"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeStep === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_ButtonSecondary, {
              class: "py-2 px-4",
              buttonText: 'Kembali',
              onClick: _ctx.prevStep
            }, null, 8, ["onClick"]),
            _createVNode(_component_ButtonPrimary, {
              class: "py-2 px-4",
              buttonText: 'Buat Order',
              onClick: _ctx.submitOrder
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_HjpConfirmationModal, {
        show: _ctx.modalConfirmation.show,
        imgContent: _ctx.modalConfirmation.imgContent,
        title: _ctx.modalConfirmation.title,
        subTitle: _ctx.modalConfirmation.subTitle,
        btnSubmitTitle: _ctx.modalConfirmation.btnSubmitTitle,
        isLoadingSubmit: _ctx.isLoadingSubmit,
        onHideDialog: _ctx.hideModalConfirmation,
        onOnSubmit: _ctx.doSubmitConfirm
      }, null, 8, ["show", "imgContent", "title", "subTitle", "btnSubmitTitle", "isLoadingSubmit", "onHideDialog", "onOnSubmit"])
    ])
  ]))
}