import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-981b4656"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-gray-500 ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.isProduction ? 'loader-primary' : 'loader-secondary')
    }, null, 2),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 64))
}