
  import { defineComponent, computed, toRefs } from 'vue'
  import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
  import VerticalHeading from '@/views/components/heading/VerticalHeading.vue'
  import RouteLine from '@/views/shared-components/RouteLine.vue'
  import { IOrderForm } from '@/utils/interfaces/iCreateOrder'

  export default defineComponent({
    components: {
      VerticalHeading,
      RouteLine
    },
    props: {
      formData: {
        type: Object as () => IOrderForm,
        required: true
      },
      environment: {
        type: String,
        required: true
      }
    },
    setup(props) {
      // Destructure props to make them reactive and accessible
      const { formData, environment } = toRefs(props)

      // Check if the current environment is production
      const isProduction = computed(() => environment.value === 'production')

      // Compute the total quantity of all items
      const totalQuantity = computed(() => formData.value.orderDetails
        .reduce((sum, item) => sum + item.packages, 0))

      // Compute the total unit of all items
      const totalItemByUnit = computed(() => formData.value.orderDetails.reduce<Record<string, number>>((acc, item) => {
          const unit = item.contractItem.unit
          acc[unit] = (acc[unit] || 0) + item.weight
          return acc
        }, {} as Record<string, number>))

      // Compute the total volume of all items
      const totalVolume = computed(() => formData.value.orderDetails
        .reduce((sum, item) => sum + item.volume, 0))

      // Compute the total price of all items
      const totalPrice = computed(() => formData.value.orderDetails
        .reduce((sum, item) => sum + (item.price * item.packages), 0))

      return {
        isProduction,
        totalQuantity,
        totalVolume,
        totalPrice,
        totalItemByUnit,
        currencyNumberFormat
      }
    },
  })
