
import { defineComponent, computed } from 'vue'
import environment from '@/configs/environtment'

export default defineComponent({
  props: {
    origin: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const isProduction = computed(() => environment.getMode() === 'production')

    return {
      isProduction
    }
  }
})
