
import Sidebar from 'primevue/sidebar'
import { ref, computed, defineComponent, onMounted } from 'vue'
import environment from '@/configs/environtment'
import {
  useStore
} from 'vuex'
import { leftMenuProduction, leftMenuStaging } from '@/data/local/json/leftmenu'
import DropdownListMenu from '@/views/shared-components/DropdownListMenu.vue'
import ButtonWhite from '@/views/components/button/ButtonWhite.vue'

export default defineComponent({
  name: 'LeftPanel',
  components: {
    Sidebar,
    DropdownListMenu,
    ButtonWhite,
  },
  setup() {
    const isProduction = computed(() => environment.getMode() === 'production')
    const items = ref(isProduction.value ? leftMenuProduction() : leftMenuStaging())

    const isLoaded = ref(false)
    const version = ref(localStorage.getItem('version'))
    const isMobile = ref(window.innerWidth <= 1024)

    const store = useStore()
    store.dispatch('setEnvironment', environment.getMode())

    const activeSlider = computed({
      get: () => store.state.toggleMenu,
      set: (val: boolean) => store.dispatch('setToggleMenu', val),
    })

    const hiddenSidebar = () => {
      activeSlider.value = false
    }

    const checkProhibitedRoles = (list: any) => {
      if (list.length < 1) return true
      const userRole = store.state?.userProfile?.RolesName
      return list.indexOf(userRole) < 0
    }

    onMounted(() => {
      isLoaded.value = true
    })

    return {
      items,
      store,
      isProduction,
      isLoaded,
      version,
      isMobile,
      activeSlider,
      hiddenSidebar,
      checkProhibitedRoles,
    }
  }
})
