
import { defineComponent, computed } from 'vue'
import environment from '@/configs/environtment'

export default defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        }
    },
    setup() {
        const isProduction = computed(() => environment.getMode() === 'production')

        return {
            isProduction
        }
    }
})
