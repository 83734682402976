
import { defineComponent, ref, getCurrentInstance, onMounted } from 'vue'
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import SearchBox from '@/views/components/form/SearchBox.vue'
import Loader from '@/views/shared-components/Loader.vue'
import DataNotFound from '@/views/shared-components/DataNotFound.vue'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { ContractRoute } from '@/utils/interfaces/iCreateOrder'

const contractEndpoint = 'openapi/v1/contractroutes'

export default defineComponent({
  components: {
    ModalDefault,
    Paginator,
    SearchBox,
    Loader,
    DataNotFound
  },
  props: {
    show: Boolean,
    width: {
      type: String,
      default: '960px'
    }
  },
  emits: ['hideDialog', 'selectRoute'],

  setup(_, { emit }) {
    const inputSearch = ref('')
    const contractRoutes = ref<ContractRoute[]>([])
    const selectedRoute = ref<ContractRoute | null>(null)
    const isLoading = ref(false) 

    const app = getCurrentInstance()
    const { $icon } = app?.appContext.config.globalProperties ?? {}

    const pagination = ref({
      pageNumber: 1,
      pageSize: 5,
      totalRecords: 0,
      rowPerPageOptions: [5, 10, 25]
    })

    const hideModal = () => {
      emit('hideDialog')
    }

    const selectRoute = (item: ContractRoute) => {
      selectedRoute.value = item
      emit('selectRoute', item)
      hideModal() 
    }

    const getContracts = async () => {
      isLoading.value = true 
      try {
        const response = await apiUseCase.get(`${contractEndpoint}${returnUrlPrams({
          pageNumber: pagination.value.pageNumber,
          pageSize: pagination.value.pageSize,
          filterField: ['originName'],
          search: inputSearch.value,
        })}`)
        
        // Map API response to ContractRoute objects
        const result = response.result.data
        contractRoutes.value = result.map((data: any) => ({
          id: data.id,
          contractId: data.contractId,
          contractName: data.contractName,
          name: data.name,
          originName: data.originName,
          destinationName: data.destinationName,
        }))

        pagination.value.totalRecords = response.result.count
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        isLoading.value = false 
      }
    }

    const onSearch = () => {
      pagination.value.pageNumber = 1
      getContracts()
    }

    const onChangePage = (pageState: { page: number; rows: number }) => {
      pagination.value.pageSize = pageState.rows
      pagination.value.pageNumber = pageState.page + 1
      getContracts()
    }

    onMounted(() => {
      getContracts()
    })

    return {
      inputSearch,
      contractRoutes,
      selectedRoute,
      pagination,
      isLoading, 
      icon: $icon,
      selectRoute,
      hideModal,
      onSearch,
      onChangePage
    }
}
})
