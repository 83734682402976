
import DataTable from '@/views/components/table/HjpDataTable.vue'
import FormExample from '@/views/pages/coba/FormExample.vue'
import { ref } from 'vue'

export default {
  components: {
    DataTable,
    FormExample
  },
  setup() {
    const tabelParams = ref({
      endpoint: '/openapi/v1/contractroutes',
      columnsTable: [
        {
          field: 'contractName',
          headerName: 'Contract ID',
        },
        {
          field: 'originName',
          headerName: 'Origin',
        },
        {
          field: 'destinationName',
          headerName: 'Destination',
          sortable: true
        },
      ]
    })
    return {
      tabelParams
    }
  },
}
