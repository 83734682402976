<template>
    <div class="image-left lg:w-4/6 hidden lg:block relative">
        <div class="flex flex-col items-center justify-between wrap pt-14">
            <img src="@/assets/img/logo-black.png" class="h-6 self-start mb-39" alt="logo">
            <div class="flex flex-col items-center info py-3 mt-16">
                <img src="@/assets/img/illustration-login.png" alt="logo">
                <div class="mt-10 font-bold text-4xl">SELAMAT DATANG REKAN MIISI</div>
                <div class="text-2xl">Tingkatkan kemudahan dan kecepatan pengiriman produk Anda bersama MIISI</div>
            </div>
            <img src="@/assets/img/logo-mg.png" class="mb-3" alt="logo-mg">
        </div>
    </div>
</template>