
import { defineComponent, ref, watch, onMounted, getCurrentInstance } from 'vue'
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import SearchBox from '@/views/components/form/SearchBox.vue'
import Loader from '@/views/shared-components/Loader.vue'
import DataNotFound from '@/views/shared-components/DataNotFound.vue'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { ContractItem } from '@/utils/interfaces/iCreateOrder'

const baseEndpoint = 'openapi/v1/contractitems'

export default defineComponent({
  components: {
    ModalDefault,
    Paginator,
    SearchBox,
    Loader,
    DataNotFound
  },
  props: {
    show: Boolean,
    width: {
      type: String,
      default: '960px'
    },
    existingSelectedItems: {
      type: Array as () => ContractItem[],
      default: () => []
    }
  },
  emits: ['hideDialog', 'selectItems', 'removeItems'],

  setup(props, { emit }) {
    const inputSearch = ref('')
    const ContractItems = ref<ContractItem[]>([])
    const selectedItems = ref<ContractItem[]>([...props.existingSelectedItems])
    const isLoading = ref(false)

    const pagination = ref({
      pageNumber: 1,
      pageSize: 5,
      totalRecords: 0,
      rowPerPageOptions: [5, 10]
    })

    // Get the current instance to access global properties
    const app = getCurrentInstance()
    const { $icon } = app?.appContext.config.globalProperties ?? {}

    const hideModal = () => emit('hideDialog')

    const toggleSelectItem = (item: ContractItem) => {
      const exists = selectedItems.value.some(selected => selected.id === item.id)
      if (exists) {
        selectedItems.value = selectedItems.value.filter(selected => selected.id !== item.id)
        emit('removeItems', item.id)
      } else {
        selectedItems.value.push(item)
        emit('selectItems', selectedItems.value)
      }
    }

    const isSelected = (item: ContractItem) => selectedItems.value.some(selected => selected.id === item.id)

    const getItems = async () => {
      isLoading.value = true 
      try {
        const response = await apiUseCase.get(`${baseEndpoint}${returnUrlPrams({
          pageNumber: pagination.value.pageNumber,
          pageSize: pagination.value.pageSize,
          filterField: ['name'],
          search: inputSearch.value
        })}`)
        
        const result = response.result.data
        ContractItems.value = result.map((data: any) => ({
          id: data.id,
          contractId: data.contractId,
          contractName: data.contractName,
          name: data.name,
          unit: data.unit,
          isActive: data.isActive,
        }))

        pagination.value.totalRecords = response.result.count
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        isLoading.value = false 
      }
    }

    const onSearch = () => {
      pagination.value.pageNumber = 1
      getItems()
    }

    const onChangePage = (pageState: { page: number; rows: number }) => {
      pagination.value.pageSize = pageState.rows
      pagination.value.pageNumber = pageState.page + 1
      getItems()
    }

    watch(() => props.existingSelectedItems, (newVal) => {
      selectedItems.value = [...newVal]
    }, { immediate: true })

    onMounted(getItems)

    return {
      inputSearch,
      ContractItems,
      selectedItems,
      pagination,
      isLoading, 
      toggleSelectItem,
      isSelected,
      hideModal,
      onSearch,
      onChangePage,
      icon: $icon
    }
  }
})
