
import { defineComponent, ref, toRefs, computed, onMounted } from 'vue'
import { Validation } from '@vuelidate/core'
import FormInput from '@/views/components/form/FormInput.vue'
import FormInputGroup from '@/views/components/form/FormInputGroup.vue'
import FormTextarea from '@/views/components/form/FormTextarea.vue'
import RouteLine from '@/views/shared-components/RouteLine.vue'
import DashedLine from '@/views/components/line/DashedLine.vue'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import { hasError, errorMessage } from '@/utils/helpers/validation'
import { IOrderForm } from '@/utils/interfaces/iCreateOrder'
import ModalSelectRoute from './ModalSelectRoute.vue'

export default defineComponent({
  components: {
    FormInput,
    FormInputGroup,
    FormTextarea,
    DashedLine,
    ButtonPrimary,
    ModalSelectRoute,
    RouteLine
  },
  props: {
    formData: {
      type: Object as () => IOrderForm,
      required: true,
    },
    v$: {
      type: Object as () => Validation,
      required: true,
    },
    environment: {
      type: String, 
      required: true, 
    }
  },
  setup(props) {
    const localFormData = ref({ ...props.formData })
    const isProduction = computed(() => props.environment === 'production')
    const isModalVisible = ref(false)

    const showModalSelectRoute = () => {
      isModalVisible.value = true
    }

    const hideModalSelectRoute = () => {
      isModalVisible.value = false
    }

    const handleSelectRoute = (route: { id: string; contractId: string; contractName: string; name: string; originName: string; destinationName: string }) => {
      localFormData.value.contractRoute.id = route.id 
      localFormData.value.contractRoute.contractId = route.contractId
      localFormData.value.contractRoute.name = route.name
      localFormData.value.contractRoute.contractName = route.contractName
      localFormData.value.contractRoute.originName = route.originName
      localFormData.value.contractRoute.destinationName = route.destinationName
    }

    onMounted(() => {   
      if (localFormData.value.contractRoute.id !== '') {
        handleSelectRoute(localFormData.value.contractRoute)  
      }
    })

    return { 
      localFormData, 
      ...toRefs(props.v$),
      isModalVisible,
      showModalSelectRoute,
      hideModalSelectRoute,
      handleSelectRoute,
      isProduction,
      hasError,
      errorMessage
    }
  },
})
